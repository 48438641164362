import React, { Component, Suspense, useContext } from "react";
import { HashRouter, Route, Routes, Outlet, Navigate } from "react-router-dom";
import "./scss/style.scss";
import { AuthContext, AuthProvider } from "./Context/AuthContext";
import ErrorBoundary from "./components/errorboundary/errorboundary";
const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);
//Containers.
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
//site
const Site = React.lazy(() => import("./site/site"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const PrivateRoutes = () => {
	const { logado } = useContext(AuthContext);
	console.log("logado: " + logado);
	return logado ? <Outlet /> : <Navigate to="/login" />;
};
class App extends Component {
	render() {
		return (
			<ErrorBoundary>  {/* Aplicando ErrorBoundary ao redor do App */}
				<AuthProvider>
					<HashRouter>
						<Suspense fallback={loading}>
							<Routes>
								<Route
									exact
									path="/login"
									name="Login"
									element={<Login />}
								/>
								<Route
									exact
									path="/site"
									name="Web site"
									element={<Site />}
								/>
								<Route
									exact
									path="/404"
									name="Page 404"
									element={<Page404 />}
								/>
								<Route
									exact
									path="/500"
									name="Page 500"
									element={<Page500 />}
								/>
								<Route
									element={<PrivateRoutes />}
								>
									<Route
										path="*"
										name="Home"
										element={<DefaultLayout />}
									/>
								</Route>
							</Routes>
						</Suspense>
					</HashRouter>
				</AuthProvider>
			</ErrorBoundary>
		);
	}
}
export default App;