import React, { Component } from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null }; // Adicionei 'error' ao estado
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error }; // Atualizei o estado com 'error'
	}

	componentDidCatch(error, errorInfo) {
		// Aqui você pode fazer log do erro ou enviar para um serviço de monitoramento de erros
		console.error("Erro capturado:", error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// Renderiza algo ou envia o erro para o componente pai
			return <div>Algo deu errado: {this.state.error.message}</div>;
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired, // Adicionei validação para 'children'
};

export default ErrorBoundary;
