import React, { useState } from "react";
import PropTypes from "prop-types";

const AuthContext = React.createContext({});

function AuthProvider(props){
	let islogado = localStorage.getItem("logado");
	const [logado,setLogado] = useState(islogado === "S" ? true : false);
	return(
		<AuthContext.Provider value={{logado,setLogado}}>
			{props.children}
		</AuthContext.Provider>
	);
}

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export {AuthContext,AuthProvider};